import { useReducer } from "react";

import UploadDropzone from "./components/UploadDropzone";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";

// import img1 from "./assets/IMG_0533.jpg";
// import img2 from "./assets/IMG_0541.jpg";
// import img3 from "./assets/IMG_0578.jpg";
// import img4 from "./assets/IMG_0637.jpg";
// import img5 from "./assets/IMG_0836.jpg";
// import img6 from "./assets/IMG_1117.jpg";

import "./App.css";

function App() {
  const dummyPosts = {
    posts: [
      // { id: 1, images: [img1] },
      // { id: 2, images: [img2] },
      // { id: 3, images: [img3] },
      // { id: 4, images: [img4] },
      // { id: 5, images: [img5] },
      // { id: 6, images: [img6] },
    ],
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [store, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "save": {
          console.log("selected");
          return {
            posts: [
              ...state.posts,
              {
                id: Date.now(),
                images: [action.payload?.image],
                name: action.payload?.name,
              },
            ],
          };
        }
        case "setPostsOrder": {
          return {
            posts: [...action.payload],
          };
        }
        case "removePost": {
          return {
            posts: [
              ...state.posts.filter((post) => post?.id !== action.payload),
            ],
          };
        }
        default: {
          return state;
        }
      }
    },
    {
      // TODO: Remove dummyPosts
      posts: [...dummyPosts.posts],
    }
  );

  const handleFileSelect = (selectedFiles) => {
    if (selectedFiles?.length) {
      selectedFiles.forEach((selectedFile) => {
        let reader = new FileReader();

        reader.onload = (e) => {
          const { result } = e.target;

          dispatch({
            type: "save",
            payload: { image: result, name: selectedFile?.name },
          });
        };

        reader.readAsDataURL(selectedFile);
      });
    }
  };

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const posts = [...store.posts];

    // if (targetId) {
    //   const result = move(
    //     posts[sourceId],
    //     posts[targetId],
    //     sourceIndex,
    //     targetIndex
    //   );

    //   const swappedPosts = {
    //     ...posts,
    //     [sourceId]: result[0],
    //     [targetId]: result[1],
    //   };

    //   dispatch({ type: "setPostsOrder", payload: swappedPosts });
    // }

    const swappedPosts = swap(posts, sourceIndex, targetIndex);

    dispatch({ type: "setPostsOrder", payload: swappedPosts });
  }

  const removePost = (postId) => {
    dispatch({ type: "removePost", payload: postId });
  };

  return (
    <div className="App">
      <div style={{ margin: "15px 0" }}>
        <UploadDropzone
          onDropFile={handleFileSelect}
          accept=".jpg,.png,.jpeg"
          required
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="items"
            boxesPerRow={3}
            rowHeight={"200"}
            style={{
              height: `${200 * Math.ceil(store.posts.length / 3)}px`,
              width: "600px",
              minHeight: "calc(100vh - 136px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {[...store.posts].map((post, index) => (
              <GridItem key={`post-${post.id}`}>
                <div className="post" style={{ userSelect: "none" }}>
                  <img
                    src={post?.images[0]}
                    style={{ width: "100%", pointerEvents: "none" }}
                    alt="posts"
                  />
                  <button
                    style={{
                      position: "absolute",
                      right: 5,
                      top: 5,
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      background: "rgba(255,255,255, 0.2)",
                      border: "1px solid #fff",
                      color: "#fff",
                      transform: "rotate(45deg)",
                      fontSize: "19px",
                    }}
                    onClick={() => {
                      removePost(post?.id);
                    }}
                  >
                    +
                  </button>
                </div>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>

        <textarea
          style={{ width: "500px", padding: 0, margin: 0, border: 0 }}
          value={JSON.stringify(
            [
              ...store.posts.map((post) => ({
                id: post?.id,
                name: post?.name
              })),
            ].reverse(),
            null,
            2
          )}
          onChange={() => {}}
        ></textarea>
      </div>
    </div>
  );
}

export default App;
