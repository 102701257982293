import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import Loader from "components/simple/Loader";

import styles from "./upload-dropzone.module.scss";

export default function UploadDropzone({
  style,
  onDropFile,
  isLoading,
  required,
  accept,
  placeholder,
}) {
  const [file, setFile] = useState(null);
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles);
    onDropFile(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });
  return (
    <div style={style} className={styles["dropzone-container"]}>
      <div {...getRootProps()} style={{ position: "relative" }}>
        <input
          {...getInputProps()}
          style={{
            display: "input",
            width: 1,
            height: 1,
            position: "absolute",
            left: "50%",
            top: "70%",
          }}
          required={!file && required}
        />
        {/* {!file ? ( */}
          <>
            {isLoading ? (
              <Loader />
            ) : isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p>
                {placeholder ||
                  "Drag 'n' drop file here, or click to select file"}
              </p>
            )}
          </>
        {/*  ) : (
           <div style={{ padding: 12 }}>{file?.path}</div>
         )} */}
      </div>
    </div>
  );
}
